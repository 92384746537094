import { Wrapper } from "nystem-components";

const Select = ({ className, ...props }) => (
  <Wrapper
    renderAs="select"
    className={[className, "bg-black text-white"]}
    {...props}
  />
);
export default Select;
