import { useState } from "react";
import { InputWrapper, Input, UseValidator } from "nystem-components";
import app from "nystem";
import validate from "./validate";

const MapTextInput = ({ model, view, focus, setValue, value, className }) => {
  const [error, setValidated] = UseValidator({ view, validate, value, model });
  const { disabled, length, text, clearButton } = model;
  const [id] = useState(app().uuid);

  const componentClassName = [
    className,
    ...(model.className || []),
    "textInput",
  ];
  if (clearButton) componentClassName.push("flex");

  const inputClassName = ["textInputDefault"];
  if (error) inputClassName.push("textInputError");

  const [lat, long] = value || [];

  return (
    <InputWrapper
      id={id}
      model={model}
      error={error}
      className={componentClassName}
    >
      <Input
        id={id}
        placeholder={app().t(text)}
        className={model.classNameInput}
        value={lat && long ? `${long},${lat}` : ""}
        maxLength={length}
        onChange={(val) => {
          console.log("hrh", val);
          setValue(
            val
              .replace(/[^0-9-,.]/gim, "")
              .split(",")
              .reverse()
          );
        }}
        disabled={disabled}
        type="text"
        focus={model.focus || focus}
        onBlur={() => setValidated(true)}
        selectAllOnFocus={model.selectAllOnFocus}
      />
    </InputWrapper>
  );
};
export default MapTextInput;
