import React, { useRef, useState, useEffect } from "react";

const isResized = /(\/image\/.*)([0-9]{1,8})x([0-9]{1,8})\/(.*)/im;
const resizedParts = /(\/image.*\/)(([0-9]{1,8})x([0-9]{1,8})\/)(.*)/im;

let memKeys = [];

const imageLoader = (src) =>
  new Promise((resolve) => {
    if (!memKeys.includes(src)) memKeys.push(src);
    const image = new window.Image();
    image.src = src;
    image.onload = () => resolve(src);
    image.onerror = () => {
      console.log("error", src);
      resolve("error");
    };
  });

const sizeInSteps = (size) => {
  const step = size < 500 ? 50 : size < 1000 ? 100 : 250;
  return (Math.trunc(size / step) + 1) * step;
};

const ResizedImage = ({ src, fixed, className, limit, ...props }) => {
  const imageRef = useRef();
  const [, prepend, , setWidth, setHeight, filename] = resizedParts.exec(src);

  const counter = useRef(0);
  const [style, setStyle] = useState({});
  const [imgSrc, setImgSrc] = useState(false);

  const density = window.devicePixelRatio;

  className =
    className instanceof Array
      ? className
          .flat(Infinity)
          .filter((item) => item)
          .join(" ")
      : className;
  className = className || "";

  useEffect(() => {
    let size;
    const loadCache = async () => {
      let keys = memKeys || [];
      if (!keys.length)
        try {
          const nystemCache = await window.caches.open("nystem");
          keys = (await nystemCache.keys()).map((item) => item.url);
          memKeys = keys;
          // eslint-disable-next-line no-empty
        } catch (e) {}

      size = keys
        .filter((key) => key.includes(filename))
        .reduce((curr, key) => {
          let [, , width = 10000] = resizedParts.exec(key) || [];
          width = parseInt(width, 10);
          return width > curr ? width : curr;
        }, 0);
    };

    const loadImage = async () => {
      let width;
      let height;

      const setSize = () => {
        const { offsetWidth, offsetHeight } = imageRef.current;

        const compWidth = (setWidth / setHeight) * offsetHeight;
        const compHeight = (setHeight / setWidth) * offsetWidth;
        width = compWidth < offsetWidth ? offsetWidth : compWidth;
        height = compHeight < offsetHeight ? offsetHeight : compHeight;

        if (Number.isNaN(width)) width = 50;
        if (Number.isNaN(height)) height = 50;

        setStyle({ width, height });
      };

      const load = async () => {
        counter.current++;
        if (counter.current > 10) return;

        if (!imageRef.current) return;

        if (size === 10000 || filename.endsWith(".svg")) {
          setImgSrc(await imageLoader(`${prepend}${filename}`));
          return;
        }

        setImgSrc(`loading`);

        if (height === 0) height = 10;

        const sizeParams = `${size}x${parseInt((size * height) / width, 10)}/`;
        const imgWidth = sizeInSteps(width * density);

        setImgSrc(
          await imageLoader(
            `${prepend}${size > 50 ? sizeParams : "50x50/"}${filename}`
          )
        );

        if (width === 0) return;
        if (limit && imgWidth > limit)
          setImgSrc(await imageLoader(`${prepend}/${filename}`));
        else if (size < parseInt(imgWidth, 10))
          setImgSrc(
            await imageLoader(
              `${prepend}${parseInt(imgWidth, 10)}x${parseInt(
                (imgWidth * height) / (width || 1),
                10
              )}/${filename}`
            )
          );
      };

      setSize();
      await loadCache();
      load();
    };

    let resizeTimer = false;

    let width = window.innerWidth;
    const handleResizeDebounce = () => {
      if (width === window.innerWidth) return;
      width = window.innerWidth;

      if (resizeTimer) clearTimeout(resizeTimer);
      resizeTimer = setTimeout(handleResize, 200);
    };

    const handleResize = () => {
      setStyle({});
      setImgSrc(false);
      loadImage();
    };

    window.addEventListener("resize", handleResizeDebounce);

    loadImage();
    return () => {
      window.removeEventListener("resize", handleResizeDebounce, false);
      if (resizeTimer) clearTimeout(resizeTimer);
    };
  }, [density, filename, limit, prepend, setHeight, setWidth, src]);

  if (!imgSrc)
    return (
      <div
        className={className}
        ref={imageRef}
        style={{
          ...style,
          backgroundColor: "rgba(0,0,0,0.05)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
    );

  if (imgSrc === "error")
    return (
      <div
        className={className}
        ref={imageRef}
        style={{
          ...style,
          backgroundColor: "rgba(0,0,0,0.05)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ErrorIcon />
      </div>
    );
  if (imgSrc === "loading")
    return (
      <div
        className={className}
        ref={imageRef}
        style={{
          ...style,
          backgroundColor: "rgba(0,0,0,0.05)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loadsvg />
      </div>
    );

  return (
    <img
      className={className}
      ref={imageRef}
      alt={props.alt}
      {...props}
      src={imgSrc}
      style={style}
    />
  );
};

const FullSizeImage = ({ className, ...props }) => {
  const imageRef = useRef();
  const [error, setError] = useState(false);

  className =
    className instanceof Array
      ? className
          .flat(Infinity)
          .filter((item) => item)
          .join(" ")
      : className;

  return error ? (
    <div
      className={className}
      ref={imageRef}
      style={{
        ...props.style,
        backgroundColor: "rgba(0,0,0,0.05)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <ErrorIcon />
    </div>
  ) : (
    <img
      className={className}
      onError={() => {
        setError(true);
      }}
      ref={imageRef}
      alt={props.alt}
      {...props}
    />
  );
};

const Image = (props) =>
  isResized.test(props.src) ? (
    <ResizedImage {...props} />
  ) : (
    <FullSizeImage {...props} />
  );

const Loadsvg = () => (
  <svg
    version="1.0"
    width="64px"
    height="64px"
    viewBox="0 0 128 128"
    style={{ width: "50%", height: "50%", opacity: "0.7" }}
  >
    <g>
      <linearGradient id="linear-gradient">
        <stop offset="0%" stopColor="#ffffff" fillOpacity="0" />
        <stop offset="100%" stopColor="#dddddd" fillOpacity="1" />
      </linearGradient>
      <path
        d="M63.85 0A63.85 63.85 0 1 1 0 63.85 63.85 63.85 0 0 1 63.85 0zm.65 19.5a44 44 0 1 1-44 44 44 44 0 0 1 44-44z"
        fill="url(#linear-gradient)"
        fillRule="evenodd"
      />
      <animateTransform
        attributeName="transform"
        type="rotate"
        from="0 64 64"
        to="360 64 64"
        dur="2440ms"
        repeatCount="indefinite"
      />
    </g>
  </svg>
);

const ErrorIcon = ({ style }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 1000 1000"
    enableBackground="new 0 0 1000 1000"
    xmlSpace="preserve"
    style={
      style || {
        width: "50%",
        height: "50%",
        opacity: "0.7",
        maxHeight: "100px",
        maxWidth: "100px",
      }
    }
  >
    <g>
      <path d="M847.5,154.7C758.4,65.8,635.4,10.5,500,10.5c-135.4,0-256.5,55.2-345.7,144.2C65.3,243.7,10,364.8,10,500c0,135.3,55.3,258.1,144.3,347.1c89.1,89,210.2,142.4,345.7,142.4c135.4,0,258.4-53.3,347.5-142.4C936.5,758.1,990,635.2,990,500C990,364.8,936.6,243.7,847.5,154.7z M84.9,500c0-113.9,48.1-217.2,122.9-291.9C282.6,133.4,386,85.3,500,85.3c101.7,0,193.8,35.7,265.4,96.3L181.2,765.2C120.6,693.5,84.9,601.6,84.9,500z M794,793.7c-74.8,74.7-178.1,121-294,121c-100.9,0-193.4-36.3-265.2-96.5l583.8-583.2c60.2,71.8,96.6,164.1,96.6,264.9C915.2,615.7,868.8,718.9,794,793.7z" />
    </g>
  </svg>
);
export default Image;
