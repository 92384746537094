import app from "nystem";
import { Input, Icon, Wrapper } from "nystem-components";
import { useEffect } from "react";

const FloatTimeSec = ({ model, value }) => {
  value = value || 0;
  const h = parseInt(value / 60 / 60, 10);

  let min = parseInt(value / 60, 10) - h * 60;
  if (min < 10) min = `0${min}`;

  let sec = parseInt(value, 10) - h * 60 * 60 - min * 60;
  if (sec < 10) sec = `0${sec}`;

  return (
    <Wrapper renderAs={model.renderAs} className={model.className}>
      {`${h ? `${h}:` : ""}${min}:${sec}`}
    </Wrapper>
  );
};

const MediaControl = ({ model, value, className, setValue }) => {
  // eslint-disable-next-line prefer-destructuring
  if (value instanceof Array) value = value[0];
  value = value || {};
  className = model.className ? model.className.join(" ") : "";

  useEffect(() => {
    if (!model.autoplay) return;

    setTimeout(() => {
      setValue({ ...value, playing: true });
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (model.controlType === "slider")
    return (
      <Input
        placeholder={app().t(model.text)}
        className={className}
        value={parseFloat(value.currentTime || 0)}
        onChange={(currentTime) => {
          // eslint-disable-next-line prefer-destructuring
          if (value instanceof Array) value = value[0];
          setValue({ ...value, currentTime: parseFloat(currentTime) });
        }}
        type="range"
        min={0}
        max={value.duration || 0}
        disabled={!value.duration}
        aria-label={app().t("Track position")}
      />
    );

  if (model.controlType === "position")
    return <FloatTimeSec model={model} value={parseFloat(value.currentTime)} />;
  if (model.controlType === "duration")
    return <FloatTimeSec model={model} value={parseFloat(value.duration)} />;

  const byType = {
    playpause: () => {
      setValue({ ...value, playing: !value.playing });
    },
    minus10: () => {
      let currentTime = value.currentTime - 10;
      if (currentTime < 0) currentTime = 0;
      setValue({ ...value, currentTime });
    },
    plus10: () => {
      let currentTime = value.currentTime + 10;
      if (currentTime > value.duration) currentTime = value.duration;
      setValue({ ...value, currentTime });
    },
  };
  const iconByType = {
    playpause: !value.playing ? "play" : value.isPlaying ? "pause" : "spinner",
    minus10: "arrow-rotate-left",
    plus10: "arrow-rotate-right",
  };

  return (
    <Icon
      onClick={byType[model.controlType]}
      icon={iconByType[model.controlType]}
      className={className}
      aria-label={app().t(value.playing ? "Pause" : "Play")}
      role="button"
    />
  );
};

export default MediaControl;
