import { Wrapper, ContentTypeRender } from "nystem-components";
import app from "nystem";
import React, { useState, useEffect, useRef } from "react";

const MediaPlaylist = ({ model, value, path = "", view }) => {
  const { className } = model;
  const [at, setAt] = useState(false);

  value = value instanceof Array ? value[0] : value || {};

  useEffect(() => {
    if (view.value.pos === 1) setAt(1);
    const nextInPlayList = ({ pos }) => {
      if (pos === view.value.pos - 1) setAt(1);
    };
    app().on("nextInPlayList", nextInPlayList);

    return () => {
      app().off("nextInPlayList", nextInPlayList);
    };
  }, [model.id, view]);

  useEffect(() => {
    if (at === 2 && value.isPlaying === false) {
      setAt(0);
      app().event("nextInPlayList", { pos: view.value.pos });
    }
    if (at === 1 && value.isPlaying === false) {
      value.isPlaying = true;
      setAt(2);
    }
  }, [at, value, view.value.pos]);

  return (
    <Wrapper className={className}>
      <ContentTypeRender
        path={path.replace(model.id, "")}
        items={model[`item${at}`] || []}
      />
    </Wrapper>
  );
};

export default MediaPlaylist;
