import { Wrapper, DragAndDropView } from "nystem-components";
import { useRef, useState } from "react";

let atZ = 0;

const DragAndDropDraggable = ({ model, className, path, view }) => {
  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);
  const [z, setZ] = useState(model.startZ + atZ++);
  const ref = useRef();
  const limit = {};
  setTimeout(() => {
    view.event("dragAndDropEnd");
  });

  return (
    <Wrapper
      ref={ref}
      className={[className, model.className]}
      style={{
        left: `${left}px`,
        top: `${top}px`,
        zIndex: z,
      }}
      onClick={() => {
        setZ(model.startZ + atZ++);
      }}
    >
      <DragAndDropView
        model={{ ...model, limit }}
        onEnd={(pos) => {
          setLeft(pos.x + left);
          setTop(pos.y + top);
          setZ(model.startZ + atZ++);
        }}
        path={path}
      />
    </Wrapper>
  );
};
export default DragAndDropDraggable;
