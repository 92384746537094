import { ContentTypeView, Wrapper } from "nystem-components";
import { useEffect } from "react";
import app from "nystem";

const isset = (val) => !!val && !(val instanceof Array && val.length === 0);

const ReferenceFiltered = ({ model, view, value = [], path }) => {
  value = value instanceof Array ? value : [value];
  const { renderFormat, className, source, itemClassName } = model;

  useEffect(() => {
    const check = async () => {
      const { condition } = model;

      const checkOne = async (id) => {
        const { data } = await app().database[model.source].get({ id });

        for (let i = 0; i < condition.length; i++) {
          const val = app().insertVal(condition[i][1], view, path);

          let test = data[condition[i][0]];

          if (test === "false") {
            if (!isset(val)) return true;
            continue;
          }

          if (test === "undefined") {
            if (val === undefined) return true;
            continue;
          }

          if (test === "true") {
            if (isset(val)) return true;
            continue;
          }

          const reverse = test[0] === "!";
          if (reverse) test = test.substring(1);
          test = new RegExp(model.notExact ? test : `^${test}$`, "i");

          if (test.test(val)) {
            if (!reverse) return true;
          } else if (reverse) return true;
        }
        return false;
      };
      const filtered = value.map(checkOne);
    };
    check();
  }, [model, path, view]);

  const option = (item) => (
    <ContentTypeView
      key={item}
      contentType={source}
      baseView={view}
      format={renderFormat || "view"}
      id={item}
      params={view.params}
      className={itemClassName}
      onReference={(item) => view.event(item.event, { ...item, model, path })}
    />
  );

  return <Wrapper className={className}>{value.map(option)}</Wrapper>;
};
export default ReferenceFiltered;
