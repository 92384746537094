/* eslint-disable no-new */
import { Wrapper, InputWrapper, UseValidator, MapDot } from "nystem-components";
import app from "nystem";
import { useEffect, useRef, useState } from "react";
import "./view.css";
import { Point } from "ol/geom.js";
import { Map, View } from "ol/index.js";
import { OSM } from "ol/source.js";
import { Tile as TileLayer } from "ol/layer.js";
import { useGeographic } from "ol/proj.js";
import "ol/ol.css";
import validate from "./validate";

// eslint-disable-next-line react-hooks/rules-of-hooks
useGeographic();

const MapInput = ({ value, setValue, model, view }) => {
  const mapRef = useRef();
  const [id] = useState(app().uuid);
  const [error] = UseValidator({ view, validate, value, model });

  useEffect(() => {
    if (value || !model.default !== "devicePos") return;
    app()
      .event("mapsDevicePos")
      .then(({ latitude, longitude }) => {
        setValue([latitude, longitude, model.zoom]);
      });
  }, [model, setValue, value]);

  useEffect(() => {
    const target = mapRef.current;
    let mapBaseField = false;
    if (model.mapBaseField)
      mapBaseField = view.baseView.baseView.getValue(model.mapBaseField);

    const fallbackPos = model.default
      ? model.default.split(",").reverse()
      : [13.012, 55.587];
    let coordinate = value;

    const tileLayer = new TileLayer({
      source: new OSM({
        wrapX: false,
      }),
    });

    const map = new Map({
      layers: [tileLayer],
      view: new View({
        center: mapBaseField || coordinate || fallbackPos,
        zoom: model.zoom || 8,
      }),
      target,
    });

    map.on("moveend", (e) => {
      if (!model.areaType) return;
      coordinate = map.getView().getCenter();

      view.setValue(coordinate);
    });

    let feature = false;
    if (!model.areaType) {
      [feature] = MapDot({
        type: "default",
        points: {
          coordinate: coordinate || fallbackPos,
          color: "blue",
          shape: "dot",
        },
        map,
        tileLayer,
        onClickType: "selected",
        onClick: (pos) => {
          view.event("mapClick", value[pos]);
        },
      });

      map.on("click", (event) => {
        view.setValue({
          path: model.id,
          value: event.coordinate,
        });
      });
    }

    view.on("change", ({ id, value = {} }) => {
      if (!value[model.id] && id !== model.id) return;

      coordinate = value[model.id];

      // map.getView().setZoom(zoom);

      if (model.areaType) map.getView().setCenter(coordinate);
      else feature.getGeometry().setCoordinates(coordinate);
    });

    return () => {
      target.innerHTML = "";
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InputWrapper id={id} model={model} error={error}>
      <Wrapper ref={mapRef} className={model.classNameInput}></Wrapper>
    </InputWrapper>
  );
};

export default MapInput;
/*
      if (popover) {
        popover.dispose();
        popover = undefined;
      }
      const feature = map.getFeaturesAtPixel(event.pixel)[0];
      if (!feature) {
        return;
      }
      const coordinate = feature.getGeometry().getCoordinates();
      popup.setPosition([
        coordinate[0] + Math.round(event.coordinate[0] / 360) * 360,
        coordinate[1],
      ]);

      popover = new bootstrap.Popover(element, {
        container: element.parentElement,
        content: formatCoordinate(coordinate),
        html: true,
        offset: [0, 20],
        placement: "top",
        sanitize: false,
      });
      popover.show();
      */
