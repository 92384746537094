import { useContext } from "react";
import {
  DatabaseSearchContext,
  Wrapper,
  ContentTypeRender,
  ContentTypeView,
} from "nystem-components";
import app from "nystem";

const ViewListAddYears = ({ model, view, value }) => {
  const { search } = useContext(DatabaseSearchContext);

  const val = (search && search.data) || [];
  const {
    emptyFields,
    className = [],
    headerFields,
    rowClassName = [],
  } = model;

  function createItem(item) {
    const id = ["number", "string"].includes(typeof item) ? item : item._id;

    const settings = {
      view: model,
      contentType: view.contentType,
      key: id,
      noForm: true,
      baseView: view,
      params: view.params,
      className: rowClassName,
      value: item,
    };

    return <ContentTypeView {...settings} />;
  }
  if (val.length === 0 && emptyFields && emptyFields.length > 0)
    return (
      <Wrapper className={className}>
        <ContentTypeRender items={emptyFields} />
      </Wrapper>
    );

  const { from, to } = value;
  let at = to <= 1000 ? to : from;
  let up = false;

  const newData = val.map((item) => {
    const { year } = item;
    const out = [];
    while (year >= at) {
      // eslint-disable-next-line eqeqeq
      if (year != at) out.push({ year: at, _id: app().uuid() });
      at += 10;
    }
    up = !up;
    return [...out, { ...item, up }];
  });

  while (value.to >= at && at < new Date().getFullYear() + 10) {
    if (value.to !== at) newData.push({ year: at, _id: app().uuid() });
    at += 10;
  }

  return (
    <Wrapper className={className}>
      <ContentTypeRender items={headerFields || []} />
      {newData.flat(Infinity).map(createItem)}
    </Wrapper>
  );
};
export default ViewListAddYears;
