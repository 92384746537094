import React, { useState } from "react";
import { Wrapper, Image, Audio, Video } from "nystem-components";
import app from "nystem";
import "./view.css";

const makeUrl = ({ model, value, add, view }) => {
  const { id, ext, type } = value;
  let tokenId = false;
  if (model.secure) {
    tokenId = app().uuid();
    const { contentType } = view;
    app().connection.emit({
      type: "secureFileToken",
      tokenId: `${tokenId}.${ext}`,
      fileId: `${id}.${ext}`,
      ext,
      contentType,
      modelId: model.id,
    });
  }
  const secure = model.secure ? "/secure" : "";
  return `/${type}${model.addToPath || ""}${secure}/${add || ""}${
    tokenId || id
  }.${ext}`;
};

const AudioItem = ({ value, model, view, setValue }) => (
  <Audio
    className="w-full"
    preload={model.preload}
    controls={model.controls}
    src={makeUrl({ model, value, view })}
    type="audio/mpeg"
    playing={value.playing || model.autoplay}
    currentTime={value.currentTime}
    updateInterval={model.updateInterval}
    onChange={(settings) => {
      if (setValue) setValue({ ...value, ...settings });
    }}
  />
);

const VideoItem = ({ value, model, view, setValue }) => {
  const [error, setError] = useState();
  if (error) return <AttachmentItem value={value} model={model} view={view} />;

  return (
    <Video
      preload={model.preload}
      controls={model.controls}
      className={model.itemClassName}
      src={makeUrl({ model, value, view })}
      type="video/mpg"
      playing={value.playing || model.autoplay}
      currentTime={value.currentTime}
      updateInterval={model.updateinterval}
      onChange={(settings) => {
        if (settings.error) setError(true);
        else setValue({ ...value, ...settings });
      }}
    />
  );
};

const ImageItem = ({ value, model, view, index }) => {
  const { width = 10, height = 10 } = app().image.calculateSize(value, model);

  const add =
    value.ext === "svg" || model.fullSize ? "" : `${width}x${height}/`;
  const style = model.fullSize
    ? { width: `${value.width}px`, height: `${value.height}px` }
    : {};

  const image = (
    <Image
      width={width}
      height={height}
      fixed={model.fixed}
      src={makeUrl({ model, value, add, view })}
      className={[
        !model.slideshowOnClick && model.itemClassName,
        "object-contain",
      ]}
      alt={value.name}
      limit={model.fullImageLimit}
      style={style}
      draggable={!!model.draggable}
    />
  );
  return model.slideshowOnClick ? (
    <Wrapper
      className={model.itemClassName}
      onClick={() => view?.event("slideShow", { model, index })}
    >
      {image}
    </Wrapper>
  ) : (
    image
  );
};

const AttachmentItem = ({ value, model, view }) => (
  <Wrapper
    renderAs="a"
    className={[model.itemClassName, "p-3"]}
    href={`${makeUrl({ model, value, view })}?name=${value.name}`}
    target="_blank"
  >
    {value.name || model.text}
  </Wrapper>
);

const componentByType = {
  audio: AudioItem,
  image: ImageItem,
  video: VideoItem,
  attachment: AttachmentItem,
};

const MediaView = ({ model, view, setValue, value }) => {
  value = value instanceof Array ? value : [value];
  if (!value[0]) value = [];

  return (
    <Wrapper className={model.className}>
      {value.map((value, index) => {
        const type = ["audio", "image", "video"].includes(value.type)
          ? value.type
          : "attachment";

        const Component = componentByType[type];
        return (
          <Component
            key={index}
            index={index}
            value={value}
            model={model}
            view={view}
            setValue={setValue}
          />
        );
      })}
    </Wrapper>
  );
};

export default MediaView;
