import { useEffect } from "react";

const TextareaDeviceInfo = ({ setValue, value }) => {
  useEffect(() => {
    if (!value) setValue(window.navigator.userAgent);
  }, [setValue, value]);

  return null;
};

export default TextareaDeviceInfo;
