import {
  Inserter,
  SessionRole,
  DragAndDropContext,
  TinymcePortal,
  RouterScrollpos,
  RouterPageNotFound,
  ErrorBoundry,
  PartAdmin,
  RouterQueryInserter,
} from "nystem-components";

import "./style/2_base.css";
// import "./style/4_hypothesis_maker.css";
// import "./style/5_collection.css";
// import "./style/5_mm2000.css";
// import "./style/6_observations_desktop.css";
// import "./style/7_tourist.css";
// import "./style/8_multi.css";
// import "./style/9_activitity.css";
import "./index.css";

const Index = () => (
  <ErrorBoundry
    fallback={
      <>
        <Inserter match="*" source="/content/page/error" />
      </>
    }
  >
    <RouterScrollpos />
    <DragAndDropContext>
      <TinymcePortal />
      <Inserter match="/*" source="/content/page/*" />
      <Inserter match="/admin*" source="/content/front/front" />
      <Inserter match="/" source="/content/front/front" />
      <PartAdmin />
      <SessionRole userrole="logged-out">
        <Inserter match="/admin" source="/user/login" />
      </SessionRole>
      <Inserter match="/observationer*" source="/content/headmin/front" />
      <Inserter match="/tidslinje*" source="/content/headmin/front" />
      <Inserter match="/vitnessmalsbio*" source="/content/headmin/front" />
      <Inserter match="/observationer*" source="/type/archivedrawer" />
      <Inserter match="/tidslinje*" source="/type/archivedrawer" />
      <Inserter match="/observationer*" source="/item/observations" />
      <Inserter match="/tidslinje*" source="/timelinebg/timeline" />
      <Inserter match="/vitnessmalsbio*" source="/witnessbio/player" />
      <Inserter match="/kontakt" source="/contact/add" />
      <div className="popups z-25">
        <RouterQueryInserter />
      </div>
      <Inserter match="/">
        <div className="body1">
          <Inserter match="/*" source="/content/page/*" />
          <SessionRole userrole="logged-out">
            <Inserter match="*" source="/content/frontlogin/loggedout" />
          </SessionRole>
          <RouterPageNotFound limit={5}>
            <Inserter match="*" source="/content/page/404" />
          </RouterPageNotFound>
        </div>
      </Inserter>
      <Inserter match="/information" source="/content/debugandclear" />
    </DragAndDropContext>
  </ErrorBoundry>
);

export default Index;
