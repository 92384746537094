import React, { useEffect } from "react";
import {
  Wrapper,
  ContentTypeRender,
  DatabaseOneSearch,
} from "nystem-components";
import app from "nystem";

const getId = (item, model) =>
  item.id.replace(new RegExp(`^${model.id}.`, "i"), "");

const MultigroupByRef = ({ value, view, model, path, setValue }) => {
  const { search = {} } = DatabaseOneSearch({ contentType: "language" });

  useEffect(() => {
    if (!search.data) return;

    const field = model.refField[0].id.replace(`${model.id}.`, "");

    const newVal = search.data.map(({ _id }) => {
      const oldVal = value.find((item) => item[field] === _id) || {};
      return { ...oldVal, [field]: _id };
    });

    if (JSON.stringify(value) !== JSON.stringify(newVal)) setValue(newVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.data]);

  if (!search.data) return null;

  const { className, rowClassName } = model;

  value = value || [];
  if (model.limit) value = value.slice(0, model.limit);

  return (
    <Wrapper className={className}>
      {value.map((item, index) => {
        return (
          <Wrapper key={index} className={rowClassName}>
            <ContentTypeRender
              path={`${view.getValuePath(path, model.id)}.${index}`}
              items={
                app().replaceInModel({
                  model,
                  viewFormat: view.viewFormat,
                  fn: ({ model: item }) =>
                    item.id && item.id.indexOf(model.id) === 0
                      ? { ...item, id: getId(item, model) }
                      : item,
                }).item
              }
            />
          </Wrapper>
        );
      })}
    </Wrapper>
  );
};

export default MultigroupByRef;
