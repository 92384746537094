import React, { useEffect, useRef } from "react";
import app from "nystem";

const Video = ({
  src,
  controls,
  preload,
  className,
  playing,
  onChange,
  updateInterval,
  currentTime,
}) => {
  const ref = useRef();
  const source = useRef();

  const first = useRef(true);
  useEffect(() => {
    if (!first.current) return;
    first.current = false;
  }, []);

  useEffect(() => {
    if (playing) {
      const promise = ref.current.play();
      if (promise !== undefined) {
        promise
          .catch(() => {
            onChange({ playing: false });
          })
          .then(() => {
            // Auto-play started
          });
      }
    } else ref.current.pause();
  }, [onChange, playing]);

  useEffect(() => {
    if (
      playing &&
      ref.current &&
      currentTime &&
      Math.abs(ref.current.currentTime - currentTime) > 0.01
    )
      ref.current.currentTime = currentTime;
  }, [currentTime, playing]);

  useEffect(() => {
    if (!onChange) return;

    const ended = () => {
      onChange({
        currentTime: 0,
        playing: false,
        isPlaying: false,
      });
    };
    ref.current.addEventListener("ended", ended);

    const loaded = () => {
      console.log("loaded");
    };
    ref.current.addEventListener("loadeddata", loaded);
    const error = (e) => {
      console.log("error", e.target.src, e);
      // onChange({ error: { src: e.target.src } });
    };
    source.current.addEventListener("error", error);

    const updateTick = setInterval(() => {
      if (!playing) return;
      const { duration, currentTime, readyState } = ref.current;
      const data = { currentTime, isPlaying: readyState === 4 };
      if (Number.isInteger(duration)) data.duration = duration;

      onChange(data);
    }, updateInterval || 1000);
    app().on("overlay", (data) => {
      if (data.do === "open") onChange({ playing: false });
    });
    // ref.current.currentTime = parseFloat(nextProps.currentTime);

    return () => {
      if (updateTick) clearInterval(updateTick);
    };
  }, [onChange, playing, updateInterval]);
  const ext = src.substring(src.lastIndexOf(".") + 1);

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video
      width="320"
      height="240"
      className={className.join(" ")}
      ref={ref}
      controls={controls}
      preload={preload}
      playsInline
    >
      <source ref={source} src={src} type={`video/${ext}`} />
      Your browser does not support the video element.
    </video>
  );
};
export default Video;
