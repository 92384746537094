import React, { useState, useEffect, useRef } from "react";
import { Wrapper, MediaView, StyleEventOutside } from "nystem-components";
import "./slideshow.css";

const MediaSlideshow = ({ model, value, view }) => {
  if (!value) value = [];
  value = value instanceof Array ? value : [value];
  value = value.filter((item) => item.type === "image");

  const [at, setAt] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const slideShow = ({ index, ...params }) => {
      if (params.model.id !== model.id) return;
      setAt(index);
    };
    const slideShowClose = () => {
      setAt(false);
    };
    view.on("slideShow", slideShow);
    view.on("slideShowClose", slideShowClose);

    return () => {
      view.off("slideShow", slideShow);
      view.off("slideShowClose", slideShowClose);
    };
  }, [model.id, view]);

  useEffect(() => {
    if (!ref.current) return;
    ref.current.scrollLeft = (ref.current.offsetWidth - 100) * at;
  }, [at]);

  useEffect(() => {
    if (at === false) return;

    const doByCode = {
      37: () => setAt(at !== 0 ? at - 1 : at),
      39: () => setAt(at !== value.length - 1 ? at + 1 : at),
      27: () => setAt(false),
    };

    const keyListner = (e) => {
      if (!doByCode[e.keyCode]) return;

      e.preventDefault();
      doByCode[e.keyCode]();
    };

    document.addEventListener("keydown", keyListner, false);
    return () => {
      document.removeEventListener("keydown", keyListner, false);
    };
  }, [at, value.length]);

  if (!value[0] || at === false) return null;

  return (
    <StyleEventOutside
      model={{
        className: "fixed inset-0 z-500 bg-black-opacity-25 flex h-screen",
        classNameInner:
          "scroll-snap-x-outer mx-auto mb-auto bg-white mt-10 md:p-3 p-1 rounded-md overflow-y-scroll",
        event: "slideShowClose",
      }}
      view={view}
    >
      <Wrapper ref={ref} className="overflow-scroll flex scroll-snap-x">
        {value.map((item, index) => (
          <Wrapper
            key={index}
            className={[
              "inline-block",
              "scroll-snap-x-item",
              "px-2",
              index === 0 && value.length !== 1 && "md:ml-10 ml-2",
            ]}
            onClick={() => setAt(index)}
          >
            <Wrapper className={["ml-3", value.length === 1 && "hidden"]}>
              {index + 1} of {value.length}
            </Wrapper>
            <MediaView model={model} value={item} />
          </Wrapper>
        ))}
      </Wrapper>
    </StyleEventOutside>
  );
};

export default MediaSlideshow;
