import {
  Link,
  SessionRole,
  Inserter,
  Wrapper,
  Icon,
  SessionLogout,
} from "nystem-components";
import app from "nystem";

const NavbarLink = ({ children, type, icon, ...props }) => {
  const classNames =
    "px-5 py-2 hover:bg-gray-700 hover:shadow flex items-center";

  if (type)
    props = { ...props, to: `/admin/${type}/list`, match: `/admin/${type}/*` };

  return (
    <Link {...props} className={[classNames, props.className]}>
      <Icon icon={icon} className="mr-3 h-5 w-5" />
      <Wrapper className="block flex-grow">{children}</Wrapper>
    </Link>
  );
};

const PartAdmin = () => {
  return (
    <SessionRole userrole="config super">
      <Inserter match="/admin*" className="admin m-auto max-w-6xl p-1 sm:flex">
        <Wrapper className="rounded bg-gray-800 text-gray-200 shadow sm:w-64 py-2 text-sm mb-auto mt-3">
          <NavbarLink
            icon="file"
            to={`/admin/item/list`}
            className="px-3 font-semibold"
          >
            Innehåll
          </NavbarLink>
          <NavbarLink
            icon="newspaper"
            to={`/admin/content/list`}
            className="px-3 font-semibold"
          >
            Sida
          </NavbarLink>
          <Wrapper className="h-3" />
          <NavbarLink
            icon="person"
            to={`/admin/user/list`}
            className="px-3 font-semibold"
          >
            Användare
          </NavbarLink>
          <NavbarLink
            icon="tag"
            to={`/admin/contact/list`}
            className="px-3 font-semibold"
          >
            Kontakt
          </NavbarLink>
          <SessionRole userrole="super">
            <NavbarLink
              icon="tag"
              to={`/admin/tag/list`}
              className="px-3 font-semibold"
            >
              Tagg
            </NavbarLink>
            <NavbarLink
              icon="tag"
              to={`/admin/timelinebg/list`}
              className="px-3 font-semibold"
            >
              Tidslinje bakgrund
            </NavbarLink>
            <NavbarLink
              icon="circle-question"
              to={`/admin/tutorials/list`}
              className="px-3 font-semibold"
            >
              Guider
            </NavbarLink>
            <NavbarLink
              icon="quote-left"
              to={`/admin/type/list`}
              className="px-3 font-semibold"
            >
              Typ
            </NavbarLink>
          </SessionRole>
          <SessionLogout
            type="button"
            className="px-5 py-2 hover:bg-gray-700 hover:shadow flex items-center px-3 font-semibold"
            to="/"
          >
            <Icon icon="right-from-bracket" className="mr-3 h-5 w-5" />
            <Wrapper className="block flex-grow">{app().t("Logga ut")}</Wrapper>
          </SessionLogout>
        </Wrapper>
        <Wrapper className="flex-grow px-8 pt-2 pb-32 max-w-3xl">
          <Inserter match="/admin/item/*" source="/item/*" />
          <Inserter match="/admin/tutorials/*" source="/tutorials/*" />
          <Inserter match="/admin/content/*" source="/content/*" />
          <Inserter match="/admin/timelinebg/*" source="/timelinebg/*" />
          <Inserter match="/admin/witnessbio/*" source="/witnessbio/*" />
          <Inserter match="/admin/type/*" source="/type/*" />
          <Inserter match="/admin/user/*" source="/user/*" />
          <Inserter match="/admin/tag/*" source="/tag/*" />
          <Inserter match="/admin/contact/*" source="/contact/*" />
        </Wrapper>
      </Inserter>
    </SessionRole>
  );
};

export default PartAdmin;
