import { ContentTypeView, UseLocation } from "nystem-components";

const RouterQueryInserter = ({ className, view }) => {
  const { search } = UseLocation();
  const items = search
    .split("&")
    .map((item) => {
      const [key, value] = item.split("=");
      if (!key.startsWith("popup.")) return false;

      const [, format, id] = key.split(".");
      return { contentType: value, format, id };
    })
    .filter((item) => item);

  return items.map((item) => (
    <ContentTypeView key={item.id} className={className} {...item} />
  ));
};

export default RouterQueryInserter;
