import { useState, useEffect } from "react";
import { Wrapper, Video } from "nystem-components";
import app from "nystem";

const makeUrl = ({ model, value, add, view }) => {
  const { id, ext, type } = value;
  let tokenId = false;
  if (model.secure) {
    tokenId = app().uuid();
    const { contentType } = view;
    app().connection.emit({
      type: "secureFileToken",
      tokenId: `${tokenId}.${ext}`,
      fileId: `${id}.${ext}`,
      ext,
      contentType,
      modelId: model.id,
    });
  }
  const secure = model.secure ? "/secure" : "";
  return `/${type}${model.addToPath || ""}${secure}/${add || ""}${
    tokenId || id
  }.${ext}`;
};

const MediaPreroll = ({ model, view, setValue, value }) => {
  const [error, setError] = useState();
  const [id, ext] = model.video.split(".");
  value = value instanceof Array ? value[0] : value || {};

  useEffect(() => {
    if (!model.to || model.to > (value.currentTime || 0)) return;

    setValue({ ...value, isPlaying: false });
  }, [model.to, setValue, value]);

  if (error) return null;
  return (
    <Wrapper className={model.className}>
      <Video
        className={model.className}
        preload={model.preload}
        controls={model.controls}
        src={makeUrl({
          model,
          value: { ...value, id, ext, type: "video" },
          view,
        })}
        type="video/mpg"
        playing={value.playing || model.autoplay}
        currentTime={value.currentTime || model.from || 0}
        updateInterval={model.updateinterval}
        onChange={(settings) => {
          if (settings.error) setError(true);
          else setValue({ ...value, ...settings });
        }}
      />
    </Wrapper>
  );
};

export default MediaPreroll;
