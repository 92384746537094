import { Wrapper } from "nystem-components";

const TextSplittedlabel = ({ model, value }) => {
  if (!value) return null;
  const { itemClassName, className, renderAs } = model;
  const splitby = model.splitby === "n" ? "\\n" : model.splitby;

  const items = value.split(new RegExp(splitby, "gi"));
  console.log(items.length > 1 ? model.textmany : model.textone, items);
  if (model.textone)
    return (
      <Wrapper className={className} renderAs={renderAs}>
        {items.length > 1 ? model.textmany : model.textone}
      </Wrapper>
    );

  return (
    <Wrapper className={className} renderAs={renderAs}>
      {items.map((item, i) => (
        <Wrapper key={i} className={itemClassName} renderAs={model.element}>
          {item}
        </Wrapper>
      ))}
    </Wrapper>
  );
};

export default TextSplittedlabel;
