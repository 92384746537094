/* eslint-disable no-new */
import { Wrapper, MapDot } from "nystem-components";
import { useEffect, useRef } from "react";
import { Point } from "ol/geom.js";
import { Map, View } from "ol/index.js";
import { OSM } from "ol/source.js";
import { Tile as TileLayer } from "ol/layer.js";
import { useGeographic } from "ol/proj.js";
import "ol/ol.css";

// eslint-disable-next-line react-hooks/rules-of-hooks
useGeographic();

const MapView = ({ value, model }) => {
  const mapRef = useRef();

  useEffect(() => {
    const target = mapRef.current;
    if (!value) return;
    const coordinate = value;

    const tileLayer = new TileLayer({
      source: new OSM({
        wrapX: false,
      }),
    });

    if (model.filter) {
      tileLayer.on("prerender", (evt) => {
        if (evt.context) {
          const { context } = evt;

          if ("filter" in CanvasRenderingContext2D.prototype)
            context.filter = model.filter;
          else {
            context.globalCompositeOperation = "overlay";
            context.globalAlpha = 0.5;
            context.fillStyle = "black";
            context.fillRect(0, 0, context.canvas.width, context.canvas.height);
            // https://github.com/davidenke/context-filter-polyfill?tab=readme-ov-file
          }
        }
      });

      tileLayer.on("postrender", (evt) => {
        if (evt.context) {
          const { context } = evt;
          context.filter = "none";
        }
      });
    }

    const map = new Map({
      layers: [tileLayer],
      view: new View({ center: coordinate, zoom: 8 }),
      target,
    });
    MapDot({
      type: model.dotStyle || "default",
      points: { coordinate },
      map,
      tileLayer,
    });

    map.getView().setCenter(coordinate);
    return () => {
      target.innerHTML = "";
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return <Wrapper ref={mapRef} className={model.className}></Wrapper>;
};

export default MapView;
/*
"point": {
  "coordinate": [
    12.989355669596428,
    55.61572865859654
  ],
  "zoom": 14.574194774341754
},
*/
