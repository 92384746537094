import { Wrapper, ContentTypeRender } from "nystem-components";
import { useEffect, useState } from "react";

const StyleResponsive = ({ model, path }) => {
  const { className, renderAs, item } = model;
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const update = () => {
      const cond =
        (model.height && model.height > window.screen.height) ||
        (model.width && model.width > window.screen.width);
      setVisible((cond && model.invert) || (!cond && !model.invert));
    };
    update();
    window.addEventListener("resize", update);
    return () => {
      window.removeEventListener("resize", update);
    };
  }, [model]);

  if (!visible) return null;

  return (
    <Wrapper className={className} renderAs={renderAs}>
      <ContentTypeRender path={path} items={item} />
    </Wrapper>
  );
};

export default StyleResponsive;
