import { Wrapper, ContentTypeRender } from "nystem-components";
import { useRef, useEffect, useState } from "react";

const StyleMaxHeight = ({ model, path, view }) => {
  const [top, setTop] = useState(false);
  const ref = useRef();
  useEffect(() => {
    const update = () => {
      const getTop = (el) =>
        el.offsetTop + (el.offsetParent && getTop(el.offsetParent));

      if (ref.current) setTop(getTop(ref.current));
    };
    setTimeout(update, 200);
    setTimeout(update, 20);
    view.on("dragAndDropEnd", update);
    return () => {
      view.off("dragAndDropEnd", update);
    };
  }, [view]);

  const { className, renderAs, item, offset = 0 } = model;

  return (
    <Wrapper
      ref={ref}
      className={className}
      renderAs={renderAs}
      style={{
        maxHeight: top !== false ? `calc(100vh - ${top + offset}px)` : "auto",
      }}
    >
      <ContentTypeRender path={path} items={item} />
    </Wrapper>
  );
};

export default StyleMaxHeight;
