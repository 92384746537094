import React, { useState } from "react";
import app from "nystem";

import { Wrapper, InputWrapper, Button, TextInput } from "nystem-components";

const MediaInputUrl = ({ model, setValue, value, view }) => {
  const [url, setUrl] = useState("");
  value = value || [];
  if (!(value instanceof Array)) value = [value];

  if (model.limit && value.length >= model.limit) return null;

  return (
    <InputWrapper model={{ ...model }}>
      <Wrapper>
        <TextInput
          model={{ ...model, nolabel: true }}
          view={view}
          setValue={setUrl}
          value={url}
        />
        <Button
          onClick={async () => {
            const response = await fetch(url);

            const upload = await app().event("fileUpload", {
              data: await response.arrayBuffer(),
              name: url.substring(url.lastIndexOf("/")),
              params: [
                model.secure && "secure",
                ...(model.addToPath || "").split("/"),
              ].filter((item) => item),
              contentType: view.contentType,
              modelId: model.id,
              id: app().uuid(),
              type: "image",
              ext: "webp",
            });

            setValue([
              ...value,
              {
                id: upload.id,
                ext: "webp",
                height: upload.height,
                name: upload.name,
                type: "image",
                width: upload.width,
              },
            ]);
            setUrl("");
          }}
          className="ml-1"
          size="sm"
        >
          Load
        </Button>
      </Wrapper>
    </InputWrapper>
  );
};

export default MediaInputUrl;
