/* eslint-disable no-new */
import { Wrapper } from "nystem-components";
import app from "nystem";
import { useEffect, useState } from "react";

const MapInfo = ({ model, value }) => {
  const [val, setVal] = useState();

  useEffect(() => {
    const val = value;

    const distType = ["distance", "direction"].includes(model.field);
    const update = (data) => {
      if (distType) {
        const { latitude: lat1, longitude: lng1 } = data;
        const [lng2, lat2] = val;
        try {
          setVal(app().mapAzimuth(lat1, lng1, lat2, lng2).distance);
          // console.log(app().mapAzimuth(lat1, lng1, lat2, lng2));
          // eslint-disable-next-line no-empty
        } catch (e) {}
      } else setVal(data[model.field]);
    };

    app().on("mapsDevicePos", update);
    app().event("mapsDevicePos");
    return () => {
      app().off("mapsDevicePos", update);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  return (
    <Wrapper className={model.className}>
      {val ? parseFloat(val).toFixed(model.decimal || 0) : model.fallback}
    </Wrapper>
  );
};

export default MapInfo;
