import React from "react";
import { MediaView } from "nystem-components";

const MediaStrView = (props) => {
  let { value } = props;
  value = value instanceof Array ? value : [value];
  if (!value[0]) value = [];
  value = value.map((item) => {
    if (typeof item !== "string") return item;
    const [name, ext] = item.split(".");
    return { name, id: name, ext, type: "image" };
  });

  return <MediaView {...props} value={value} />;
};

export default MediaStrView;
