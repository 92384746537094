import { Wrapper, ContentTypeRender } from "nystem-components";
import { useEffect, useRef, useState } from "react";
import app from "nystem";

const StyleScrollPos = ({ model, path, value }) => {
  const { className, renderAs, item } = model;
  const ref = useRef();
  const [visible, setVisible] = useState(model.func !== "bg");

  useEffect(() => {
    const { current } = ref;
    let left = 0;
    let width = 0;
    let scroll = 0;

    if (!current) return;

    const update = () => {
      app().event("scrollbase", { scrollLeft: current.scrollLeft });
    };
    if (model.func === "scrollbase") current.addEventListener("scroll", update);

    const updatePos = ({
      scrollLeft = scroll,
      offsetLeft = left,
      offsetWidth = width,
    }) => {
      scroll = scrollLeft;
      left = offsetLeft;
      width = offsetWidth;

      const pos = left - scroll;
      if (pos + width > 0) setVisible(true);
      current.style.mask = `linear-gradient(to right, rgba(0, 0, 0, 0) 0, ${pos}px, #000 0)`;
    };
    if (model.func === "bg") {
      app().on(`pos${value._id}`, updatePos);
      app().on("scrollbase", updatePos);
    }

    const updateWidth = () => {
      app().event(`pos${value._id}`, {
        offsetLeft: current.offsetLeft,
        offsetWidth: current.offsetWidth,
      });
    };
    if (model.func === "item") setTimeout(updateWidth, 1300);
    return () => {
      if (model.func === "bg") {
        app().off(`pos${value._id}`, updatePos);
        app().off(`scrollbase`, updatePos);
      }

      if (model.func === "scrollbase")
        current.removeEventListener("scroll", update);
    };
  }, [model, value._id]);

  return (
    <Wrapper ref={ref} className={className} renderAs={renderAs}>
      {visible && <ContentTypeRender path={path} items={item} />}
    </Wrapper>
  );
};

export default StyleScrollPos;
