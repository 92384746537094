import { useEffect, useContext, useRef } from "react";
import { PanelContext } from "nystem-components";
import app from "nystem";

const BootstrapPanelGroup = ({ model }) => {
  const { setExpanded, expanded } = useContext(PanelContext);
  const ref = useRef(app().uuid());

  useEffect(() => {
    if (expanded) app().event(model.event, { id: ref.current });
  }, [expanded, model.event]);

  useEffect(() => {
    const close = ({ id }) => {
      if (id === ref.current) return;
      setExpanded(false);
    };
    app().on(model.event, close);

    return () => {
      app().off(model.event, close);
    };
  }, [expanded, model.event, setExpanded]);

  return null;
};

export default BootstrapPanelGroup;
